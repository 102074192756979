<template>
   <transition :name="direction">
      <div v-show="visibleSlide === index" class="carousel-slide">
      <slot></slot>
   </div>
   </transition>
</template>

<script>
export default {
  props: ["visibleSlide", "index", "direction"],
  data() {
    return {

    }
  }
};
</script>

<style>

.carousel-slide {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   display: flex;
      justify-content: center;
      align-items: center;
}

.carousel-slide video {
border-radius: 10px;
  width: auto;
  height: 100%;
  transition: all 0.2s ease-in;
}


.carousel-slide img {
   border-radius: 10px;
  width: auto;
  height: 100%;
  transition: all 0.2s ease-in;
}

.container img:hover {
   transform: scale(1.05, 1.05);
}
.left-enter-active {
        animation: rightIn 0.5s ease-in-out;
    }

.left-leave-active {
        animation: rightOut 0.5s ease-in-out;
 }
 
 @keyframes leftIn{
        from  { transform: translateX(-50%); opacity: 0; }
        to { transform: translateX(0); opacity: 1 } 
}
 @keyframes leftOut{
        from  { transform: translateX(0%); opacity: 1 }
        to { transform: translateX(50%); opacity: 0 } 
}
    
    .right-enter-active {
        animation: leftIn 0.5s ease-in;
    }
    .right-leave-active {
        animation: leftOut 0.5s ease-in;
    }
    @keyframes rightOut{
        from  { transform: translateX(0%);opacity: 1 }
        to { transform: translateX(-50%); opacity: 0} 
    }
    @keyframes rightIn{
        from  { transform: translateX(50%); opacity: 0}
        to { transform: translateX(0); opacity: 1} 
    }

</style>