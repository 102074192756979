<template>

   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <div class="wrapper" :class="{ 'small': slide.makeSmall }">
            <video v-if='index === 0' autoplay controls>
               <source :src="slide.img1" type="video/mp4">
            </video>
            <img @click="storyboard" :class="{ 'cursor': index === 3}" v-else :src="slide.img1" />
            <div :class="{ 'show': index === 3}" class="counter">
               <p>{{ counter }} / 5</p>
            </div>
         </div>
      </CarouselSlide>
   </Carousel>
   <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
      props: ["isMobile"],
      data() {
         return {
            slides: [{
               title: 'Landing Page',
               img1: require("../assets/wadah/proposal.mp4"),
               info: "Wadah Archive is an alternative museum where everyday artefacts are given meaning through crowdsourced nostalgia. The online archive stresses on the idea that the stories and conversations about the artefact by people visiting the archive should be a part of the artefact itself. It is a reflection on traditional methods of preserving and displaying objects through proposing an alternative navigation system by translating physical artefacts into a digital space.",
               makeSmall: false
            }, {
               title: "Interacting with 3D objects",
               img1: require("../assets/wadah/pinStill.jpg"),
               info: "Every artefact gets its own page where a user is encouraged to pin opinions directly onto a 3D artefact - encouraging the touching of artefacts. After a pin was created, it would be added into a database called the 'Log' that housed other contributions by fellow visitors. The conversation log was designed to mimic a stream of consciousness, merging every contribution into a single joint description of the artefact only separated by pins and their semantics.",
               makeSmall: false
            }, {
               title: "Designing Pins and Signage",
               img1: require("../assets/wadah/pins.png"),
               info: "There are 7 types of pins that a user can choose between i.e asking (questions), noticing (descriptive), linking (image, video, link), reflecting (evaluating), quoting (to quote another) and other (for pins we didn't provide) that help categorise the ever increasing conversation the object encompasses",
               makeSmall: true
            }, {
               title: "Home and Index Navigation",
               img1: require("../assets/wadah/home1.jpg"),
               img2: require("../assets/wadah/home3.jpg"),
               img3: require("../assets/wadah/home2.jpg"),
               img4: require("../assets/wadah/index.jpg"),
               img5: require("../assets/wadah/index2.jpg"),
               info: "Influenced by the act of wandering in museums, we purposefully kept the index of all the artefacts at least two gestures away and added a 'randomiser' button for visitors to serendipitously land upon artefacts. This was done as a way to increase accessibility to 'visit' the artefacts globally and remove it's fragility from the viewer's gaze. By doing so, we could encourage the poking, prodding and inspection of an artefact.",
               makeSmall: false
            }, {
               title: "User Flow",
               img1: require("../assets/wadah/wireflow.png"),
               info: "A simple user journey map designed to plan out the key pages this site would have",
               makeSmall: false
            }, ],
            visibleSlide: 0,
            counter: 1,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
            this.direction = 'right'
         },
         storyboard(event) {
            if (this.visibleSlide === 3) {
               let ele = event.target
               if (this.counter < 5) {
                  this.counter++
                  ele.src = this.slides[3]['img' + this.counter]
               } else {
                  this.counter = 1
                  ele.src = this.slides[3]['img' + this.counter]
               }
            }
         }
      }
   }
</script>

<style scoped>
   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64vh;
       height: calc(var(--vh, 1vh) * 64);
   }

   .small {
      height: 30vh;
       height: calc(var(--vh, 1vh) * 30);
   }



   .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
      text-align: left;
   }


   .counter {
      position: absolute;
      top: 5vh;
       top: calc(var(--vh, 1vh) * 5);
      opacity: 0;
   }

   .show {
      opacity: 1;
   }

   .cursor {
      cursor: pointer;
      transition: all 0.2s ease-in;
   }

   .cursor:hover {
       transform: scale(1.05, 1.05);
   }


   .counter p {
      font-size: 1rem;
      font-family: "Work Sans";
   }
     @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
          height: calc(var(--vh, 1vh) * 65);
      }


      .wrapper {
         height: 26vh;
          height: calc(var(--vh, 1vh) * 26);
      }

      .small {
         overflow:scroll;
      }

      .title {
        font-size: 1.3rem;
      margin: 0 auto;
         pointer-events: none;
         white-space: normal;
         width: 80vw;
      }

        .slide-control {
          top: 80% !important;
      }


          .info {
         font-size: 0.8rem;
         text-align: center;
         width: 90vw;
         margin: 0 auto;
        margin-top: 1rem;
      }


      .counter p {
         font-size: 1.5rem;
      }
     }
</style>