<template>

   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <img v-if="index === 2" :src="slide.img" />
         <div v-else class="wrapper" :class="{ 'small': slide.makeSmall }">
            <video controls>
            <source :src="slide.img" type="video/mp4">
            </video>
         </div>
      </CarouselSlide>
   </Carousel>
   <div v-if="isMobile" class="mobile-info">
      <div class="title" :class="{ 'move': visibleSlide === 2}">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
       props: ["isMobile"],
      data() {
         return {
            slides: [
               { title: 'Would You Like A Receipt?', img: require("../assets/reciept/analyzer-promo-film_ig.mp4"), info: "An experimental chrome extension that illustrates the data being collected from us while we browse the internet. In today’s world we are constantly being categorised and analysed by institutions superficially. Our likes and dislikes are turned into goods and services against our will for the most part. The extension tries to change the way large corporations control and manipulate their user base and give them their lost sense of ‘free will’ back, exposing the hidden agenda of a centralised internet space.", makeSmall: false
               }, {
                  title: "Chrome Extension", img: require("../assets/reciept/demo-main.mp4"), info: "The receipt is designed to be satirical, pushing the user to notice and react to how they spend their time in an intangible space. It uses basic algorithmic tools to pick and distinguish between useful and useless data and summarises this in the form a barcode and a line graph. Data should be transparent and designed in a way that is largely accessible to a general audience.", makeSmall: false
               }, {
                  title: "Receipt Detail", img: require("../assets/home/receipt-optimized.gif"), info: "Close up's of receipts generated while casually browsing.", makeSmall: false
               }
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
            this.direction = 'right'
         }
      }
   }
</script>

<style scoped>

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      
   }

   .small {
      height: 30vh;
       height: calc(var(--vh, 1vh) * 30);
   }


  .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
       text-align: left;
   }

   .wrapper{
      height: 70vh;
       height: calc(var(--vh, 1vh) * 70);
   }

    @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
          height: calc(var(--vh, 1vh) * 65);
      }


      .wrapper {
         height: 26vh;
          height: calc(var(--vh, 1vh) * 26);
      }

      .small {
         overflow:scroll;
      }

      .title {
        position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         top: 68vh;
         top :calc(var(--vh, 1vh) * 68);
         pointer-events: none;
         white-space: normal;
         width: 90vw;
      }

      .move {
         top: 88vh;
          top: calc(var(--vh, 1vh) * 88);
      }



      .info {
          pointer-events: none;
         font-size: 1rem;
         text-align: center;
         width: 80vw;
         position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         bottom: 30px;
      }


    
     }


</style>