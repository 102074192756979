<template>

   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <div class="wrapper" :class="{ small: slide.makeSmall }">
            <video autoplay loop muted>
            <source :src="slide.img" type="video/mp4">
            </video>
         </div>
      </CarouselSlide>
   </Carousel>
   <!-- <div class="sitelink">
         <a target="_blank" href="https://catharsis-now-21.glitch.me/">Working Site ↗</a>
      </div> -->
   <div class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
      data() {
         return {
            slides: [
               { title: 'Ink Tools', img: require("../assets/drawingtools/ink-pen.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               }, { title: 'Ink Tools', img: require("../assets/drawingtools/ink-blot.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               },
               { title: 'Ink Tools', img: require("../assets/drawingtools/ink-brands.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               },
               { title: 'Ink Tools', img: require("../assets/drawingtools/watercolor.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               },
               { title: 'Ink Tools', img: require("../assets/drawingtools/slash-symbol.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               },
                { title: 'Ink Tools', img: require("../assets/drawingtools/symbol-draw.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               },
               { title: 'Ink Tools', img: require("../assets/drawingtools/scribble.mp4"), info: "Using CSS and Javascript to create lightweight drawing tools. In this case, ink blots spreading across damp paper.", makeSmall: false
               }
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
             this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
             this.direction = 'right'
         }
      }
   }
</script>

<style scoped>

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64vh;
   }

   .small {
      height: 30vh;
   }


  .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
       text-align: left;
   }

   .wrapper{
      height: 70vh;
   }


</style>