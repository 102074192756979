<template>
   <div id="nav">
         <div :class="{ 'active': isHome }" class="heading">
            <router-link to="/">
               <p>Laiqa Mohid</p>
            </router-link>
         </div>
         <div class="line long"></div>
         <div class="heading">
            <a target="_blank" href="https://www.instagram.com/lai.moh/?hl=en">@lai.moh</a>
         </div>
         <div class="heading">
            <a target="_blank" href="https://twitter.com/laixmoh">@laixmoh</a>
         </div>
         <div class="line"></div>
         <div class="heading">
            <a target="_blank" href="mailto:contact@laimoh.com">contact@laimoh.com</a>
         </div>
   </div>

   <div class="animated-intro">
      <p>
         <span class="text"></span>
         <span class="cursor">_</span>
      </p>
   </div>
   <div class="contain">
 <router-view v-slot="{ Component, route }">
      <transition name="fade" mode="out-in">
         <div :key="route.name">
            <component :isMobile="mobile" :is="Component"></component>
         </div>
      </transition>
   </router-view>
   </div>

</template>

<script>
   import {
      gsap
   } from "gsap";
   import {
      TextPlugin
   } from "gsap/TextPlugin";
   gsap.registerPlugin(TextPlugin);

   export default {
      data() {
         return {
            textSentence: [
               "⊹ is a computational designer ⊹",
               "is connecting our tangible and intangible worlds.",
               "is interested in creating meaningful networked interactions between humans, objects and places",
               "is bringing ephemeral and temporal experiences back into our metaverse."
            ],
            mobile: false
         }
      },
      created() {
         const isMobile = () => window.matchMedia("(max-width: 450px)").matches;
         this.mobile = isMobile();
      },
      computed: {
         isHome() {
            return this.$route.path == "/"
         },
      },
      mounted() {
         gsap.to(".cursor", {
            opacity: 0,
            ease: "power2.inOut",
            repeat: -1,
         });
         let bigTl = gsap.timeline({
            repeat: -1,
            yoyo: true,
            repeatDelay: 5
         });

         bigTl.to(".text", {
               delay: 1,
               duration: 4,
               text: this.textSentence[0],
               ease: "linear",
            })
            .to(".text", {
               delay: 4,
               duration: 6,
               text: this.textSentence[1],
               ease: "linear",
            })
            .to(".text", {
               delay: 6,
               duration: 7,
               text: this.textSentence[2],
               ease: "linear",
            })
            .to(".text", {
               delay: 6,
               duration: 7,
               text: this.textSentence[3],
               ease: "linear",
            });

      }
   }
</script>


<style>
   #app {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      width: 100vw;
      height: 100vh;
      overflow: hidden;

   }
   

   @font-face {
      font-family: "Hershey";
      src: url("./assets/fonts/Hershey-Noailles-Times-Duplex-Italic-Regular.otf") format("opentype");
   }

   @font-face {
      font-family: "Hershey bold";
      src: url("./assets/fonts/Hershey-Noailles-Times-Duplex-Italic-Bold.otf") format("opentype");
   }

   @font-face {
      font-family: "Work Sans";
      src: url("./assets/fonts/Karrik-Regular.ttf") format("truetype");
   }

   @font-face {
      font-family: "Work Sans italic";
      src: url("./assets/fonts/WorkSans-Italic-VariableFont_wght.ttf") format("truetype");
   }


     #nav {
      width: 95vw;
      height: 8vh;
      height: calc(var(--vh, 1vh) * 8);
      margin: auto;
      font-family: "Hershey bold";
      display: flex;
      justify-content: center;
      align-items: center;
   }

   .heading {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.8rem;
      margin-left: 1rem;
      margin-right: 1rem;
      white-space: nowrap;
      border-radius: 0;
      transition: all 0.2s ease-in;
      -webkit-transition: all 0.2s ease-in;
      -moz-transition: all 0.2s ease-in;
      -o-transition: all 0.2s ease-in;
   }

   .heading:hover {
      cursor: pointer;
      border: black 1px solid;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      padding: 0.5rem;
   }

   .active {
      cursor: pointer;
      border: black 1px solid;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      padding: 0.5rem;
   }

   .line {
      margin-left: 1rem;
      margin-right: 1rem;
      width: 20vw;
      height: 3px;
      background-color: black;
      border-radius: 20%;
   }

   .long {
      width: 60vw;
   }

   .animated-intro {
      position: absolute;
      top: 6vh;
      top: calc(var(--vh, 1vh) * 6);
      left: 4vw;
      padding: 5px;
      width: 30vw;
      font-size: 1.1rem;
      font-family: "Work Sans";
   }

   .project-info {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: 0;
      width: 95vw;
      height: 10vh;
      height: calc(var(--vh, 1vh) * 10);
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
   }

   .sitelink {
      font-size: 1.2rem;
      padding: 1rem;
      font-family: "Work Sans";
      position: absolute;
      right: 4rem;
      margin-top: 1rem;
      float: right;
      border-radius: 50%;
      border: 1px solid black;
      z-index: 1;
   }

   .sitelink:hover {
      opacity: 0.5;
      cursor: pointer;
   }

   /* ROUTE TRANSITIONS */

   .fade-enter-from {
      opacity: 0;
   }

   .fade-enter-active {
      transition: all 0.3s ease-out;
   }

   .fade-leave-to {
      opacity: 0;
   }

   .fade-leave-active {
      transition: all 0.3s ease-out;
   }

    /* MEDIA QUERIES */

   /* @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3),
   (-webkit-min-device-pixel-ratio: 2) { */

 @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .contain {
         height: 100vh;
         height: calc(var(--vh, 1vh) * 100);
      }

      #nav {
         width: unset;
         justify-content: flex-start;
         flex-wrap: nowrap;
         overflow-x: scroll;
         -ms-overflow-style: none;
         scrollbar-width: none;
         animation: nudgeSwipe 1.4s ease-in-out;
      }

      #nav::-webkit-scrollbar {
         display: none;
      }

      #nav  div {
         flex-shrink: 0;
      }

      .line {
         width: 20vw;
      }

      .heading {
         font-size: 1.8rem;
        
      }

      .animated-intro {
         width: 90vw;
         left: 4vw;
         font-size: 0.8rem;
      }

      @keyframes nudgeSwipe {
         0% {
            transform: translateX(0);

         }
         50% {
            transform: translateX(-80px);
            width: 150vw;
         }
         100% {
             transform: translateX(0px);

         }
      }

   }
</style>