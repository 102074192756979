<template>
  <div class="sitelink">
      <a target="_blank" href="https://companionstudio.substack.com/p/8f0c716b-9a2e-4cdb-995a-326292503487">Process Rundown ↗</a>
   </div>
   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <div class="wrapper" :class="{ 'small': slide.makeSmall }">
            <img v-if='index === 4' @click="storyboard" :class="{ 'cursor': index === 4}" :src="slide.img1" />
             <video v-else autoplay loop controls>
               <source :src="slide.img1" type="video/mp4">
            </video>
            <div :class="{ 'show': index === 4}" class="counter">
               <p>{{ counter }} / 5</p>
            </div>
         </div>
      </CarouselSlide>
   </Carousel>
   <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
    <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div> 
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
  import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

export default {
   components: {
         Carousel,
         CarouselSlide
      },
      props: ["isMobile"],
      data() {
         return {
            slides: [{
               title: 'Creating Memory Blobs',
               img1: require("../assets/memento/blob-create.mp4"),
               info: "Memento is a journalling experience that creates visual manifestations from your writings and lets you pin them to the spaces you visit. We wanted the app to be extremely easy to use and it's core functionality to not be cumbersome and get in the way of journalling. While creating a new entry, users are urged to type anywhere on a blank canvas. Visually this reduced the clutter and stress of using the app - we implemented this approach on a micro level throughout all the flows.",
               makeSmall: false
            }, {
               title: "Navigating the Index",
               img1: require("../assets/memento/index-flow.mp4"),
               info: "The index of memory blobs had two simple viwes - the first was a simple scrolable card layoutw hile the second was a map layout to engage the user in the spaces they had travelled to. The design was kept minimal to let the memory blobs take center stage in using the app. The design uses two color gradient system to add emphasis on certain elements and make the UI more gestalt.",
               makeSmall: false
            }, {
               title: "Generative Outcomes",
               img1: require("../assets/memento/Blob_Preview1.mp4"),
               info: "Here ares some pretty final outcomes when answering the questions in the digital journal.",
               makeSmall: true
            },{
               title: "Generative Outcomes",
               img1: require("../assets/memento/Blob_Preview2.mp4"),
               info:  "Here ares some pretty final outcomes when answering the questions in the digital journal.",
               makeSmall: true
            }, {
               title: "Generative Process",
               img1: require("../assets/memento/process1.gif"),
               img2: require("../assets/memento/process2.gif"),
               img3: require("../assets/memento/process3.jpeg"),
               img4: require("../assets/memento/process4.jpeg"),
               img5: require("../assets/memento/process5.jpeg"),
               info: "Using blender to bring the 2d blob experiments to life and further refining the blobs design in a real world use case. This also gave us more of an insight into what some of those parameters could be to control the form and color of the blobs within the environment mapped sphere. After picking the aesthetic, I formed a system for each base input selection, the plan was to combine those visuals from individual inputs and create a visually averaged out version as the final bubble.",
               makeSmall: true
            } ],
            visibleSlide: 0,
            counter: 1,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
            this.direction = 'right'
         },
         storyboard(event) {
            if (this.visibleSlide === 4) {
               let ele = event.target
               if (this.counter < 5) {
                  this.counter++
                  ele.src = this.slides[4]['img' + this.counter]
               } else {
                  this.counter = 1
                  ele.src = this.slides[4]['img' + this.counter]
               }
            }
         }
      }
}
</script>

<style scoped>
  .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65vh;
      height: calc(var(--vh, 1vh) * 65);
   }




   .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
      text-align: left;
   }


   .counter {
      position: absolute;
      top: 5vh;
      top: calc(var(--vh, 1vh) * 5);
      opacity: 0;
   }

   .show {
      opacity: 1;
   }

   .cursor {
      cursor: pointer;
      transition: all 0.2s ease-in;
   }

   .cursor:hover {
       transform: scale(1.05, 1.05);
   }

   .counter p {
      font-size: 1rem;
      font-family: "Work Sans";
   }
     @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
         height: calc(var(--vh, 1vh) * 65);
      }


      /* .wrapper {
         height: 26vh;
         height: calc(var(--vh, 1vh) * 26);
      } */

      .small {
         overflow: scroll;
      }

      .small video {
          transform: translateX(24%);
      }
        .small img {
          transform: translateX(24%);
      }

      .title {
        font-size: 1.3rem;
      margin: 0 auto;
         pointer-events: none;
         white-space: normal;
         width: 80vw;
          margin-top: 2rem;
      }

        .slide-control {
          top: 80% !important;
      }


       .info {
         font-size: 0.7rem;
         text-align: center;
         width: 90vw;
         margin: 0 auto;
        margin-top: 1rem;
      }

      .sitelink {
         font-size: 1rem;
         padding: 1rem;
         right: 1rem;
         width: 23vw;
         margin-top: -2.5rem;
         text-align: center;
         background-color: white;
      }


      .counter p {
         font-size: 1.5rem;
         color: white;
      }
     }
</style>