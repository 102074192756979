<template>
 <div class="sitelink">
      <a target="_blank" href="https://shekleung.com/">Working Site ↗</a>
   </div>
   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">

         <div v-if="slide.img2" class="container">
            <div class="wrapper">
               <video loop autoplay>
                  <source :src="slide.img" type="video/mp4">
               </video>
            </div>
            <div class="wrapper-mobile">
                  <video loop autoplay>
                     <source :src="slide.img2" type="video/mp4">
                  </video>
            </div>
         </div>

         <div v-else class="single" :class="{ 'small': slide.makeSmall }">
                  <video autoplay controls>
                     <source :src="slide.img" type="video/mp4">
                  </video>
         </div>
      </CarouselSlide>
   </Carousel>
    <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div v-if="!isMobile" class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
      props: ["isMobile"],
      data() {
         return {
            slides: [{
               title: 'Home',
               img: require("../assets/shek/home2.mp4"),
               info: "Rebranding and website developed for Shek Leung. Intentionally designed to be minimal so as to let the imagery take center stage. The design also stays evergreen to garments switching styles and messages through each collection. The transition and effects were kept to a light fade so as to enhance the cinematic nature of the garments.",
               makeSmall: false
            },
            {
               title: 'Collections',
               img: require("../assets/shek/ma-intro.mp4"),
               img2: require("../assets/shek/ba-intro.mp4"),
               info: "Allowing no visual clutter when it comes to the interface involves the viewer in the image. Everything was minified down and enlarged including the text describing the collection so as to allow the viewer to absorb the content slowly.",
               makeSmall: false
            },{
               title: 'About',
               img: require("../assets/shek/about-m.mp4"),
               img2: require("../assets/shek/about.mp4"),
               info: "A key feature of this section was the brand catering to audiences of different languages. It is easy to switch between english and mandarin so as to welcome a global audience. The contact button is also always available at the corner of the screen as a call to action.",
               makeSmall: false
            },{
               title: 'Press',
               img: require("../assets/shek/press-m.mp4"),
               img2: require("../assets/shek/press.mp4"),
               info: "The image gallery of press interviews is usually text based in competing brands, however the audience being a visually inclined one would prefer to click on images. Once again, there is an easily accessible contact button to make the navigation simple.",
               makeSmall: false
            },{
               title: 'Projects',
               img: require("../assets/shek/proj-m.mp4"),
               img2: require("../assets/shek/projects.mp4"),
               info: "Since the brand had created films in the past, this section serves to be an archive of music and film especially composed for the collection or to propel the start of a collection.",
               makeSmall: false
            }],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
             this.direction = 'right'
         }
      }
   }
</script>

<style scoped>
   .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 73%;
   }

   video {
      border: 1px solid black;
   }

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 55vh;
       height: calc(var(--vh, 1vh) * 55);
      margin-right: 1rem;
   }

   .single {
       display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
       height: calc(var(--vh, 1vh) * 60);
   }

   .wrapper-mobile {
      height: 55vh;
       height: calc(var(--vh, 1vh) * 55);

      margin-right: 1rem;
   }

   .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
      text-align: left;
   }

   @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
       height: calc(var(--vh, 1vh) * 65);
         flex-direction: column;
      }

      .wrapper {
         height: 37vh;
       height: calc(var(--vh, 1vh) * 37);

         
      }
      .wrapper-mobile {
         height: 25vh;
  height: calc(var(--vh, 1vh) * 25);
         margin: 0;
      }

      .single {
         height: 25vh;
         height: calc(var(--vh, 1vh) * 25);
         margin: 0;
      }

      .sitelink {
         font-size: 1rem;
         padding: 1rem;
         right: 1rem;
         width: 23vw;
         margin-top: -2.5rem;
         text-align: center;
         background-color: white;
      
      }

   
      .title {
  pointer-events: none;
              font-size: 1.3rem;
      margin: 0 auto;
         pointer-events: none;
         white-space: normal;
         width: 80vw;
          margin-top: 2rem;
      }


      .info {
         pointer-events: none;
         font-size: 0.7rem;
         text-align: center;
         width: 90vw;
         margin: 0 auto;
        margin-top: 1rem;
      }
   }
</style>