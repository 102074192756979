<template>
   <div class="carousel">
      <slot></slot>
      <div class="slide-control">
         <button @click="prev" class="prev">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black" stroke-width="2" stroke-miterlimit="10" />
               <path d="M15.2375 20.2375L11 16L15.2375 11.7625" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
               <path d="M11 16H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>
         <button @click="next" class="next">
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path
                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                  stroke="black" stroke-width="2" stroke-miterlimit="10" />
               <path d="M16.7625 20.2375L21 16L16.7625 11.7625" stroke="black" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round" />
               <path d="M11 16H21" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
         </button>
      </div>
   </div>

</template>

<script>
   export default {
      data() {
         return {

         }
      },
      methods: {
         next() {
            this.$emit('next')
         },
         prev() {
            this.$emit('prev')
         }
      }

   };
</script>

<style scoped>
   .carousel {
      position: relative;
      height: 85vh;
      height: calc(var(--vh, 1vh) * 85);
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .slide-control {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 90vw;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }

   button {
      outline: none;
   }

   button:active,
   button:hover {
      outline: none;
      cursor: pointer;
      opacity: 0.5;
   }

     @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {
      .carousel {
         margin-top: 3rem;
        height: calc(var(--vh, 1vh) * 65);
      }

      svg {
         fill: white;
      }
   }
</style>