<template>
 <div class="sitelink">
         <a target="_blank" href="https://catharsis-now-21.glitch.me/">Working Site ↗</a>
      </div>
   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <div class="wrapper" :class="{ small: slide.makeSmall }">
            <video v-if='index === 0' autoplay muted controls>
            <source :src="slide.img" type="video/mp4">
            </video>
            <img v-else :src="slide.img" />
         </div>
      </CarouselSlide>
   </Carousel>
    <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div v-if="!isMobile" class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
       props: ["isMobile"],
      data() {
         return {
            slides: [
               { title: 'Catharsis Now', img: require("../assets/catharsis/shortIntro.mp4"), info: "Catharsis Now is a live data visualisation that tries to comfort and support an isolated audience by highlighting shared experiences between members of an online community.", makeSmall: false
               }, {
                  title: "Displaying Data", img: require("../assets/catharsis/happy.png"), info: "An explorative and non linear way to interact with people's catharthic experiences, where posts aren't organised by reddit's upvote or 'trending' system.", makeSmall: false
               }, {
                  title: "Finding Unity", img: require("../assets/catharsis/red.png"), info: "Showcasing the 'off my chest community by highlighting their commonalities through sentiment analysis, time and linking common keywords between posts, reflecting community characteristics within the interfaces design.", makeSmall: false
               }
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
             this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
             this.direction = 'right'
         }
      }
   }
</script>

<style scoped>

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 65vh;
       height: calc(var(--vh, 1vh) * 65);
   }

   .small {
      height: 30vh;
       height: calc(var(--vh, 1vh) * 30);
   }


  .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
       text-align: left;
   }

   @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
          height: calc(var(--vh, 1vh) * 65);
      }

      .wrapper {
         height: 45vh;
          height: calc(var(--vh, 1vh) * 45);
      }

      .small {
         overflow:scroll;
      }

      .title {
        position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         top: 75vh;
          top: calc(var(--vh, 1vh) * 75);
         pointer-events: none;
         white-space: normal;
         width: 80vw;
      }

       .sitelink {
         font-size: 1rem;
         padding: 1rem;
         right: 1rem;
         width: 22vw;
         margin-top: 1rem;
      }


      .info {
          pointer-events: none;
         font-size: 1rem;
         text-align: center;
         width: 80vw;
         position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         bottom: 30px;
      }

     }
 

</style>