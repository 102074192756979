<template>
   <div class="sitelink">
      <a target="_blank" href="https://sonic-type.glitch.me/">Working Site ↗</a>
   </div>
   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide"  :direction="direction">

         <div v-if="slide.img2" class="container">
            <div class="wrapper">
               <video controls>
                  <source :src="slide.img" type="video/mp4">
               </video>
            </div>
            <div class="wrapper">
               <video controls>
                  <source :src="slide.img2" type="video/mp4">
               </video>
            </div>
         </div>

         <div v-else class="single" :class="{ small: slide.makeSmall }">
            <video controls>
               <source :src="slide.img" type="video/mp4">
            </video>
         </div>
      </CarouselSlide>
   </Carousel>
   <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
       props: ["isMobile"],
      data() {
         return {
            slides: [{
                  title: 'Typed Speech',
                  img: require("../assets/typedspeech/intro.mp4"),
                  info: "Typed Speech is a generative typography project. The website allows users to speak and create their own typographic forms to better represent how they speak.",
                  makeSmall: false
               },
               {
                  title: 'Generated Results',
                  img: require("../assets/typedspeech/dreaming.mp4"),
                  img2: require("../assets/typedspeech/g-afternoon.mp4"),
                  info: "Language came about as a way to express feelings about our external and internal state of mind. However, Most used fonts today like Helvetica and Univers follow a minimalistic, accessible and uniform form factor. For most people it represents convenience and neutrality, this however deters from the original purpose of writing, there is a loss of individuality, expression and narration.",
                  makeSmall: false
               }, {
                  title: 'Generated Results',
                  img: require("../assets/typedspeech/heck.mp4"),
                  img2: require("../assets/typedspeech/interesting.mp4"),
                  info: "The project analyses voice data through FFT analysis. The outputted data is then organised into bass and treble values. A mean average of each of these is calculated and mapped to a range of numbers. The mapped number then influences individual letterforms that feed into the shape of the overall sentence.",
                  makeSmall: false
               }, {
                  title: 'Generated Results',
                  img: require("../assets/typedspeech/ladybird2.mp4"),
                  img2: require("../assets/typedspeech/xcuse.mp4"),
                  info: "I experimented with several speach to text tools, however I ultimately decided to create the first prototype with Web Speech API so I could easily test it with different voices. I used opentype.js's library to help me trace the letterforms to a set of points on a graph. The voice values the system recived then displaced these points across a grid. The displacement had to be subtle so the shapes created would only differ lightly from one another",
                  makeSmall: false
               }, {
                  title: 'Generated Results',
                  img: require("../assets/typedspeech/hi-there.mp4"),
                  img2: require("../assets/typedspeech/wonder.mp4"),
                  info: "I experimented with several speach to text tools, however I ultimately decided to create the first prototype with Web Speech API so I could easily test it with different voices. I used opentype.js's library to help me trace the letterforms to a set of points on a graph. The voice values the system recived then displaced these points across a grid. The displacement had to be subtle so the shapes created would only differ lightly from one another",
                  makeSmall: false
               }
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
            this.direction = 'right'
         }
      }
   }
</script>

<style scoped>
   .container {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
   }

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 25vh;
      height: calc(var(--vh, 1vh) * 25);
      margin-bottom: 2rem;
   }

   .carousel-slide video {
      border: solid 1px black;
       height: 99%;

   }


   .single {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60vh;
      height: calc(var(--vh, 1vh) * 60);
   }

   .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
      text-align: left;
   }
    
     @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 40vh;
         height: calc(var(--vh, 1vh) * 40);
         flex-direction: column;
      }

      .wrapper {
         height: 12vh;
         height: calc(var(--vh, 1vh) * 12);
         
      }
      .wrapper-mobile {
         height: 25vh;
         height: calc(var(--vh, 1vh) * 25);
         margin: 0;
      }

      .single {
         height: 25vh;
         height: calc(var(--vh, 1vh) * 25);
         margin: 0;
      }

      .sitelink {
           font-size: 1rem;
         padding: 1rem;
         right: 1rem;
         width: 23vw;
         margin-top: -2.5rem;
         text-align: center;
         background-color: white;
      
      }

   
      .title {
         pointer-events: none;
              font-size: 1.3rem;
      margin: 0 auto;
         pointer-events: none;
         white-space: normal;
         width: 80vw;
          margin-top: 2rem;
      }


      .info {
          pointer-events: none;
         font-size: 0.7rem;
         text-align: center;
         width: 90vw;
         margin: 0 auto;
        margin-top: 1rem;
      }
   }
 
</style>