import {
   createRouter,
   createWebHistory
} from 'vue-router'
   import Home from '../views/Home.vue'
   import DrawingTools from '../views/DrawingTools.vue'
   import Memento from '../views/Memento.vue'
   import WadahArchive from '../views/WadahArchive.vue'
   import CatharisNow from '../views/CatharsisNow.vue'
   import WindPoetry from '../views/WindPoetry.vue'
   import TypedSpeech from '../views/TypedSpeech.vue'
   import Receipt from '../views/Receipt.vue'
   import ShekLeung from '../views/ShekLeung.vue'

const routes = [{
      path: '/',
      name: 'Home',
      component: Home
   }, 
   {
      path: '/DrawingTools',
      name: 'Drawing Tools',
      component: DrawingTools
   },
   {
      path: '/Memento',
      name: 'Memento',
      component: Memento
   },
   {
      path: '/WadahArchive',
      name: 'Wadah Archive',
      component: WadahArchive
   },
   {
      path: '/CatharisNow',
      name: 'Catharsis Now',
      component: CatharisNow
   },
   {
      path: '/TypedSpeech',
      name: 'Typed Speech',
      component: TypedSpeech
   },
   {
      path: '/WindPoetry',
      name: 'Wind Poetry',
      component: WindPoetry
   },
   {
      path: '/Receipt',
      name: 'Would You Like A Reciept?',
      component: Receipt
   },
   {
      path: '/ShekLeung',
      name: 'Shek Leung',
      component: ShekLeung
   }

]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes
})

export default router