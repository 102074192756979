<template>

   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide" :direction="direction">
         <div class="wrapper" :class="{ small: slide.makeSmall }">
            <video v-if='index === 0 || index === 1' autoplay muted controls>
            <source :src="slide.img" type="video/mp4">
            </video>
            <img v-else :src="slide.img" />
         </div>
      </CarouselSlide>
   </Carousel>
  <div v-if="isMobile" class="mobile-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
   
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slides[visibleSlide].title }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slides[visibleSlide].info }} </p>
      </div>
   </div>
</template>

<script>
   
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
       props: ["isMobile"],
      data() {
         return {
            slides: [
               { title: 'Wind Poetry', img: require("../assets/wind/wind_poetry-demo-1.mp4"), info: "A quick experiment in trying to bring intangible data into a tangible reality. Taking several images throughout living in a new city, I wanted to document the transition I felt after moving here. My body adapting to the weather changes here was testament to how humans perceive adaption and change through largely visceral experiences.", makeSmall: false
               }, {
                  title: "Nuanced data visualisation", img: require("../assets/wind/wind_poetry-demo.mp4"), info: "Using Weather APIs, I was able to get wind direction in real time for two cities - London and Jakarta which is represented by the floating photographs. If the user blows on the screen, it pushes the photos away in the actual direction of the wind respective to each city. I used google’s teachable machine to detect these “wind sounds”. An experiment in using data to create rich user experiences.", makeSmall: false
               }
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
             this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
             this.direction = 'right'
         }
      }
   }
</script>

<style scoped>

   .wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64vh;
      height: calc(var(--vh, 1vh) * 64);
   }

   .small {
      height: 30vh;
      height: calc(var(--vh, 1vh) * 30);
   }


  .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
       text-align: left;
   }

   .wrapper{
      height: 70vh;
      height: calc(var(--vh, 1vh) * 70);
   }

   @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
         height: calc(var(--vh, 1vh) * 65);
      }

      .wrapper {
         height: 45vh;
         height: calc(var(--vh, 1vh) * 45);
      }

      .small {
         overflow:scroll;
      }

      .title {
        position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         top: 75vh;
         top: calc(var(--vh, 1vh) * 75);
         pointer-events: none;
         white-space: normal;
         width: 92vw;
      }

       .sitelink {
         font-size: 1rem;
         padding: 1rem;
         right: 1rem;
         width: 22vw;
         margin-top: 1rem;
      }


      .info {
          pointer-events: none;
         font-size: 1rem;
         text-align: center;
         width: 80vw;
         position: absolute;
         margin: 0 auto;
         left: 0;
         right: 0;
         bottom: 30px;
      }

     }
 


</style>