<template>

   <Carousel @next="next" @prev="prev">
      <CarouselSlide v-for="(slide, index) in slides" :key="slide" :index="index" :visibleSlide="visibleSlide"
         :direction="direction">
         <div :class="{ 'small': index === 5 }" class="container">
            <router-link :to="{ name: slideTitle[visibleSlide] }"><img :src="slide" /></router-link>
         </div>
      </CarouselSlide>
   </Carousel>
   <div v-if="isMobile" class="mobile-info">
      <div class="wrap">
         <div class="ticker">
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
            <p :class="{ 'break': visibleSlide === 6 }" class="item"> {{ slideTitle[visibleSlide] }} </p>
         </div>
      </div>
      <div class="info">
         <p> {{ slideInfo[visibleSlide] }} </p>
      </div>
   </div>
   <div v-else class="project-info">
      <div class="title">
         <p> {{ slideTitle[visibleSlide] }}</p>
      </div>
      <div class="line long"></div>
      <div class="info">
         <p> {{ slideInfo[visibleSlide]}} </p>
      </div>
   </div>
</template>

<script>
   import Carousel from "../components/Carousel.vue";
   import CarouselSlide from "../components/CarouselSlide.vue";

   export default {
      components: {
         Carousel,
         CarouselSlide
      },
      props: ["isMobile"],
      data() {
         return {
            slides: [
               //  require("../assets/home/preview-ink.gif"),
               require("../assets/home/blobs.gif"),
               require("../assets/home/wadah.gif"),
               require("../assets/home/shek-ba.gif"),
               require("../assets/home/catharsis.gif"),
               require("../assets/home/wind2.gif"),
               require("../assets/home/typedspeech.gif"),
               require("../assets/home/receipt-optimized.gif"),
            ],
            slideTitle: [
               // "Drawing Tools",
               "Memento",
               "Wadah Archive",
               "Shek Leung",
               "Catharsis Now",
               "Wind Poetry",
               "Typed Speech",
               "Would You Like A Reciept?",
            ],
            slideInfo: [
               // "Creating web animations that function as drawing tools or inspired by real life drawing materials i.e ink, pen and pencil.",
               "Memento is an augemented reality journalling app. In efforts to reconnect people back to their surroundings, the app creates generated visualisations through user input and thus begins to serve as a spatial memory log for their users.",
               "A digital museum where everyday artefacts are given meaning through crowdsourced nostalgia. It is a reflection on traditional methods of preserving and displaying objects through proposing an alternative navigation system by translating physical artefacts into a digital space.",
               "Rebranding and website developed for Shek Leung. Intentionally designed to be minimal so as to let the imagery take center stage.",
               "A live data visualisation that tries to comfort and support an isolated audience by highlighting shared experiences between members of an online community.",
               "A playful way to interact with images and the places they represent. Teaching a computer to experience human sensory nuances, in this case the sensation of wind.",
               "A generative typography project. The website allows users to speak and create their own typographic forms to better represent how they speak. The project analyses voice data through FFT analysis.",
               "An experimental chrome extension that illustrates the data being collected from us while we browse the internet.",
            ],
            visibleSlide: 0,
            direction: 'left'
         }
      },
      computed: {
         slidesLen() {
            return this.slides.length
         }
      },
      methods: {
         next() {
            if (this.visibleSlide >= this.slidesLen - 1) {
               this.visibleSlide = 0
            } else {
               this.visibleSlide++
            }
            this.direction = 'left'
         },
         prev() {
            if (this.visibleSlide <= 0) {
               this.visibleSlide = this.slidesLen - 1
            } else {
               this.visibleSlide--
            }
            this.direction = 'right'
         }
      }
   }
</script>

<style scoped>
   .container {
      height: 70vh;
      height: calc(var(--vh, 1vh) * 70);
   }

   .small {
      height: 35vh;
      height: calc(var(--vh, 1vh) * 35);
   }

   .title {
      font-size: 1.8rem;
      font-family: "Hershey bold";
      white-space: nowrap;
      text-align: center;
      margin-right: 1rem;
   }

   .info {
      font-size: 0.8rem;
      font-family: "Work Sans";
      margin-left: 1rem;
      text-align: left;
   }

   /* @media only screen and (min-device-width: 320px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3),
   (-webkit-min-device-pixel-ratio: 2) { */

   @media only screen and (min-device-width: 320px) and (max-device-width: 812px) {

      .container {
         height: 65vh;
         height: calc(var(--vh, 1vh) * 65);
      }

      .wrap {
         overflow: hidden;
         margin-top: 1.4rem;
      }

      .ticker {
         display: flex;
         animation: tickerMove linear 6s infinite;
      }

      .ticker:hover {
         animation-play-state: paused;
      }

      .item {
         font-size: 1.3rem;
         font-family: "Hershey bold";
         white-space: nowrap;
         text-align: center;
         flex-shrink: 0;
         width: 11rem;
         box-sizing: border-box;
         margin-right: 2rem;
      }

      .break {
         white-space: normal;
         font-size: 1.3rem;
      }

      .info {
         font-size: 0.8rem;
         text-align: center;
         width: 90vw;
         margin: 0 auto;
        margin-top: 1rem;
      }

      @keyframes tickerMove {
         0% {
            transform: translate3d(0, 0, 0);
         }

         100% {
            transform: translate3d(-39rem, 0, 0);
         }
      }
      

   }
</style>